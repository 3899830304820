import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Button from "@material-ui/core/Button";

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <h1>Privacy policy</h1>

    <h2>External parties</h2>
    <p>
      The aplication uses Google Firebase to gather information how the users
      interact with the app. By using our app, you agree that your data will be
      processed and stored by Google LLC.
    </p>
    <h2>Private data storage</h2>
    <p>
      Unless you have placed an order for buying furniture, all private data
      resides on the device only. If you have placed an order, your email and
      name will be sent to our partners for order fulfillment and will be
      subject to their privacy policy. Your data will be kept on our side for as
      long as we have doubts whether your order was fulfilled.
    </p>

    <h2>Anonymous storage</h2>
    <p>
      Aside from the data being sent to the external parties, the application
      will send some diagnostic data to our servers. We will be using it to
      assess the manner in which the application is being used. This includes
      the pose of the device, placed virtual objects and the current tracking
      status. The data will not include any information which allows for
      unambigous user identification. Moreover, we will not send any data which
      is strictly not necessary for assessing the interaction with our app.
      {/* The polls which are filled on our website will be stored within our
      company. We will use this information for the following purposes:
      <ul>
        <li>assessing market needs &amp; pricing</li>
        <li>estimating market segments</li>
        <li>
          contacting to deliver the requested information about the released
          products
        </li>
      </ul> */}
    </p>

    <h2>Right to be forgotten</h2>
    <p>
      If you have placed an order on the shop, use the contact form on this
      website to have your personal data deleted. Alternatively, use the contact
      form inside our app. If you have not placed an order, there is no personal
      data held anywhere, only anonymous usage statistics.
    </p>

    <div style={{ marginBottom: "1.45rem" }}>
      <a href="/">
        <Button variant="contained" color="black">
          Lear more about PanoRoom →
        </Button>
      </a>
    </div>
  </Layout>
);

export default PrivacyPolicy;
